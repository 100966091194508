export const setTransportsStats = (transportsStats) =>
	({
		type    : 'SET_TRANSPORTS_STATS',
		payload : { transportsStats }
	});

export const setProducersStats = (producersStats) =>
	({
		type    : 'SET_PRODUCERS_STATS',
		payload : { producersStats }
	});

export const setPeerProducersStats = (peerProducersStats) =>
	({
		type    : 'SET_PEER_PRODUCERS_STATS',
		payload : { peerProducersStats }
	});