/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */

import { config } from '../config';

const list = [
	{ name: 'Български', file: 'bg', locale: [ 'bg', 'bg-bg' ] }, // Bulgarian
	{ name: 'Deutsch', file: 'de', locale: [ 'de', 'de-de' ] }, // German
	{ name: 'English', file: 'en', locale: [ 'en', 'en-en' ] }, // English
	{ name: 'Español', file: 'es', locale: [ 'es', 'es-es' ] }, // Spanish
	{ name: 'Français', file: 'fr', locale: [ 'fr', 'fr-fr' ] }, // French
	{ name: 'Italiano', file: 'it', locale: [ 'it', 'it-it' ] }, // Italian
	{ name: 'Polski', file: 'pl', locale: [ 'pl', 'pl-pl' ] }, // Polish
	{ name: 'العربية', file: 'ar', locale: [ 'ar', 'ar-ea' ] }, // Arabic
	{ name: 'Čeština', file: 'cs', locale: [ 'cs', 'cs-cs' ] }, // Czech
	{ name: '简体中文', file: 'cn', locale: [ 'zh', 'zh-zh', 'zh-cn' ] }, // Chinese (Simplified)
	{ name: '繁體中文', file: 'tw', locale: [ 'zh-tw', 'zh-hk', 'zh-sg' ] }, // Chinese (Traditional)
	{ name: 'Hrvatski', file: 'hr', locale: [ 'hr', 'hr-hr' ] }, // Croatian
	{ name: 'Dansk', file: 'dk', locale: [ 'dk', 'dk-dk' ] }, // Danish
	{ name: 'Ελληνικά', file: 'el', locale: [ 'el', 'el-el' ] }, // Greek
	{ name: 'हिन्दी', file: 'hi', locale: [ 'hi', 'hi-hi' ] }, // Hindi
	{ name: 'Magyar', file: 'hu', locale: [ 'hu', 'hu-hu' ] }, // Hungarian
	{ name: 'Қазақша', file: 'kk', locale: [ 'kk', 'kk-kz ' ] }, // Kazakh
	{ name: 'Latviešu', file: 'lv', locale: [ 'lv', 'lv-lv' ] }, // Latvian
	{ name: 'Norsk', file: 'nb', locale: [ 'nb', 'nb-no' ] }, // Norwegian
	{ name: 'Português', file: 'pt', locale: [ 'pt', 'pt-pt' ] }, // Portuguese
	{ name: 'Română', file: 'ro', locale: [ 'ro', 'ro-ro' ] }, // Romanian
	{ name: 'Русский', file: 'ru', locale: [ 'ru', 'ru-ru' ] }, // Russian
	{ name: 'Türkçe', file: 'tr', locale: [ 'tr', 'tr-tr' ] }, // Turkish
	{ name: 'Українська', file: 'uk', locale: [ 'uk', 'uk-uk' ] } // Ukrainian
];

export const detect = () =>
{
	const localeFull = (navigator.language ||
		(navigator as any).browserLanguage).toLowerCase();

	// const localeCountry = localeFull.split(/[-_]/)[0];

	// const localeRegion = localeFull.split(/[-_]/)[1] || null;

	return localeFull;
};

export const getList = () =>
{
	const languagesAvailable = [ ...config.languagesAvailable ];

	if (!languagesAvailable.includes('en'))
		languagesAvailable.push('en');

	let currentLocale = detect();

	if (!currentLocale)
		currentLocale = 'en';

	let compareFunction: (a: { name: string }, b: { name: string }) => number;

	try
	{
		// Test if the browser supports the locale and options arguments in localeCompare
		'a'.localeCompare('b', currentLocale);

		// if no exception, it does
		compareFunction = (a, b) => a.name.localeCompare(b.name, currentLocale);
	}
	catch (e)
	{
		// Fallback if localeCompare does not support the locale argument
		compareFunction = (a, b) => a.name.localeCompare(b.name);
	}

	return list
		.filter((item) => item.locale.some((locale) => languagesAvailable.includes(locale)))
		.sort(compareFunction);
};

export interface ILocale {
  name: string;
  file: string;
  locale: string[];
  messages: any;
}

export const loadOne = (locale: string): ILocale =>
{
	let res: any = {};

	const languages = getList();

	try
	{
		res = languages.filter(
			// (item) => item.locale.includes(locale) || item.locale.includes(locale.split(/[-_]/)[0])
			(item) => item.locale.includes(locale)
		)[0];

		res.messages = require(`./translations/${res.file}`);
	}
	catch
	{
		res = languages.filter((item) => item.locale.includes('en'))[0];

		res.messages = require(`./translations/${res.file}`);
	}

	return res;
};
