import React, { useRef } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { withRoomContext } from '../RoomContext';
import * as roomActions from '../store/actions/roomActions';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import Logger from '../Logger';

const styles = (theme) =>
	({
		dialogPaper :
		{
			width                          : '50vw',
			padding                        : theme.spacing(2),
			[theme.breakpoints.down('lg')] :
			{
				width : '60vw'
			},
			[theme.breakpoints.down('md')] :
			{
				width : '70vw'
			},
			[theme.breakpoints.down('sm')] :
			{
				width : '80vw'
			},
			[theme.breakpoints.down('xs')] :
			{
				width : '90vw'
			}
		},
		dialogActions :
		{
			flexDirection                  : 'row',
			[theme.breakpoints.down('xs')] :
			{
				flexDirection : 'column'
			}
		},
		logo :
		{
			marginLeft  : theme.spacing(1.5),
			marginRight : 'auto'
		},
		divider :
		{
			marginBottom : theme.spacing(3)
		},
		buttonContainer :
		{
			display       : 'flex',
			flexDirection : 'row',
			width         : '100%'
		},
		shutdownButton :
		{
			width         : '25vh',
			height        : '12vh',
			maxHeight     : '25%',
			maxWidth      : '12vw',
			textTransform : 'uppercase'
		},
		dividerButtons :
		{
			flexGrow : 1
		}
	});

const logger = new Logger('ShutdownDialog');

const ShutdownDialog = ({
	roomClient,
	shutdownOpen,
	classes,
	handleSetShutdownOpen
}) =>

{
	const buttonYes = useRef();

	const handleEnterKey = (event) =>
	{
		if (event.key === 'Escape' || event.key === 'Esc')
		{
			handleSetShutdownOpen(false);
		}
	};

	return (
		<Dialog
			onKeyDown={handleEnterKey}
			open={shutdownOpen}
			onClose={() => handleSetShutdownOpen(false)}
			classes={{
				paper : classes.dialogPaper
			}}
		>
			<DialogTitle dividers>
				<FormattedMessage
					id='label.shutdownOrRestart'
					defaultMessage='Shutdown or restart terminal'
				/>
			</DialogTitle>
			<DialogContent dividers>
				<div className={classes.buttonContainer}>
					<Button
						variant='contained'
						color='secondary'
						type='button'
						className={classes.shutdownButton}
						onClick={() => roomClient.shutdown('shutdown')}
					>
						<FormattedMessage
							id='label.shutdown'
							defaultMessage='Shutdown terminal'
						/>
					</Button>
					<div className={classes.dividerButtons} />
					<Button
						variant='contained'
						color='secondary'
						type='button'
						className={classes.shutdownButton}
						onClick={() => roomClient.shutdown('restart')}
					>
						<FormattedMessage
							id='label.restart'
							defaultMessage='Restart terminal'
						/>
					</Button>
				</div>
			</DialogContent>
			<DialogActions className={classes.dialogActions}>
				<Button
					onClick={() => handleSetShutdownOpen(false)}
					color='primary'
					startIcon={<CancelIcon />}
				>
					<FormattedMessage
						id='label.cancel'
						defaultMessage='Cancel'
					/>
				</Button>
			</DialogActions>
		</Dialog>
	);
};

ShutdownDialog.propTypes =
{
	roomClient            : PropTypes.object.isRequired,
	shutdownOpen          : PropTypes.bool.isRequired,
	handleSetShutdownOpen : PropTypes.func.isRequired,
	classes               : PropTypes.object.isRequired
};

const mapStateToProps = (state) =>
	({
		shutdownOpen : state.room.shutdownOpen
	});

const mapDispatchToProps = {
	handleSetShutdownOpen : roomActions.setShutdownOpen
};

export default withRoomContext(connect(
	mapStateToProps,
	mapDispatchToProps,
	null,
	{
		areStatesEqual : (next, prev) =>
		{
			return (
				prev.room.shutdownOpen === next.room.shutdownOpen
			);
		}
	}
)(withStyles(styles)(ShutdownDialog)));
