import Logger from './Logger';

const logger = new Logger('Spotlight');

export default class Spotlights
{
	constructor(maxSpotlights, hideNoVideoParticipants, roomClient)
	{
		this._maxSpotlights = maxSpotlights;
		this._peerList = [];
		this._activeVideoConsumers = [];
		this._selectedSpotlights = [];
		this._currentSpotlights = [];
		this._roomClient = roomClient;
		this._hideNoVideoParticipants = hideNoVideoParticipants;
		this._currentPage = 1;
		this._spotlightsUpdatedQueue = [];
		this._isProcessingSpotlightsUpdatedQueue = false;
	}

	addPeers(peers)
	{
		for (const peer of peers)
		{
			if (peer.isRecorder)
				continue;

			if (this._peerList.indexOf(peer.id) === -1)
			{
				logger.debug('adding peer [peerId: "%s"]', peer.id);

				if (peer.loggedInWithPin)
					this._peerList.unshift(peer.id);
				else
					this._peerList.push(peer.id);
			}
		}
		this._spotlightsUpdated();
	}

	peerInSpotlights(peerId)
	{
		return this._currentSpotlights.indexOf(peerId) !== -1;
	}

	addPeerToSelectedSpotlights(peerId)
	{
		logger.debug('addPeerToSpotlight() [peerId:"%s"]', peerId);

		this._selectedSpotlights = [ ...this._selectedSpotlights, peerId ];

		// tu specjalnie ustawiamy nie przez setter, bo zaraz będzie update
		this._currentPage = 1;

		this._spotlightsUpdated();
	}

	removePeerFromSelectedSpotlights(peerId)
	{
		logger.debug('removePeerSpotlight() [peerId:"%s"]', peerId);

		this._movePeersRemovedFromSelectedToFront([ peerId ]);

		this._selectedSpotlights =
			this._selectedSpotlights.filter((peer) =>
				peer !== peerId);

		this._spotlightsUpdated();
	}

	clearPeersFromSelectedSpotlights(doUpdate = true)
	{
		logger.debug('clearPeersFromSpotlights()');

		this._movePeersRemovedFromSelectedToFront(this._selectedSpotlights);

		this._selectedSpotlights = [];

		if (doUpdate)
			this._spotlightsUpdated();
	}

	_movePeersRemovedFromSelectedToFront(removedPeers)
	{
		for (let i = removedPeers.length - 1; i >= 0; i--)
		{
			const peerId = removedPeers[i];
			const index = this._peerList.indexOf(peerId);

			if (index > -1)
			{
				this._peerList.splice(index, 1);
				this._peerList = [ peerId ].concat(this._peerList);
			}
		}
	}

	clearSpotlights()
	{
		this._peerList = [];
		this._activeVideoConsumers = [];
		this._selectedSpotlights = [];
		this._currentSpotlights = [];
		this._spotlightsUpdatedQueue = [];
		this._isProcessingSpotlightsUpdatedQueue = false;
	}

	newPeer(peer)
	{
		if (peer.isRecorder)
			return;

		logger.debug(
			'room "newpeer" event [id: "%s"]', peer.id);

		if (!this._peerList.includes(peer.id))
		{
			logger.debug('_handlePeer() | adding peer [peerId: "%s"]', peer.id);

			if (peer.loggedInWithPin)
				this._peerList.unshift(peer.id);
			else
				this._peerList.push(peer.id);

			this._spotlightsUpdated();
		}
	}

	closePeer(id)
	{
		logger.debug(
			'room "peerClosed" event [peerId:%o]', id);

		this._peerList = this._peerList.filter((peer) => peer !== id);
		this._activeVideoConsumers = this._activeVideoConsumers.filter((consumer) =>
			consumer.peerId !== id);
		this._selectedSpotlights = this._selectedSpotlights.filter((peer) => peer !== id);
		this._spotlightsUpdated();
	}

	addSpeakerList(speakerList)
	{
		logger.debug('addSpeakerList speakerList %o', speakerList);

		const newSpeakerList= speakerList.filter((peerId) => this._peerList.includes(peerId));

		logger.debug('addSpeakerList speakerList AFTER filter %o', newSpeakerList);

		this._peerList = [ ...new Set([ ...newSpeakerList, ...this._peerList ]) ];
		this._spotlightsUpdated();
	}

	handleActiveSpeaker(peerId)
	{
		logger.debug('handleActiveSpeaker() [peerId:"%s"]', peerId);

		const indexPeerList = this._peerList.indexOf(peerId);
		const indexCurrentSpotlights = this._currentSpotlights.indexOf(peerId);

		if (indexPeerList > -1 && indexCurrentSpotlights === -1)
		{
			this._peerList.splice(indexPeerList, 1);
			this._peerList = [ peerId ].concat(this._peerList);

			this._spotlightsUpdated();
		}
	}

	addVideoConsumer(newConsumer)
	{
		if (newConsumer.kind === 'video' && (newConsumer.source === 'webcam' ||
			newConsumer.source === 'extravideo' || newConsumer.source === 'screen') &&
			this._activeVideoConsumers.findIndex((consumer) =>
				consumer.consumerId === newConsumer.id) === -1)
		{
			this._activeVideoConsumers.push({
				consumerId     : newConsumer.id,
				peerId         : newConsumer.peerId,
				remotelyPaused : newConsumer.remotelyPaused });

			this._spotlightsUpdated();
		}
	}

	removeVideoConsumer(consumerId)
	{
		const oldLength = this._activeVideoConsumers.length;

		this._activeVideoConsumers = this._activeVideoConsumers.filter((consumer) =>
			consumer.consumerId !== consumerId);

		if (oldLength !== this._activeVideoConsumers.length)
			this._spotlightsUpdated();
	}

	resumeVideoConsumer(consumerId)
	{
		const videoConsumer = this._activeVideoConsumers.find((consumer) =>
			consumer.consumerId === consumerId);

		if (videoConsumer)
		{
			videoConsumer.remotelyPaused = false;
			this._spotlightsUpdated();
		}
	}

	pauseVideoConsumer(consumerId)
	{
		const videoConsumer = this._activeVideoConsumers.find((consumer) =>
			consumer.consumerId === consumerId);

		if (videoConsumer)
		{
			videoConsumer.remotelyPaused = true;
			this._spotlightsUpdated();
		}
	}

	_hasActiveVideo(peerId)
	{
		if (this._activeVideoConsumers.findIndex((consumer) =>
			consumer.peerId === peerId && !consumer.remotelyPaused) !== -1)
		{
			return true;
		}

		return false;
	}

	async _processSpotlightsUpdatedQueue()
	{
		this._isProcessingSpotlightsUpdatedQueue = true;

		// przetwarzamy tylko najświeższy update specjalnie w loop aby zabezpieczyć rownoległość
		while (this._spotlightsUpdatedQueue.length > 0)
		{
			const lastElement = this._spotlightsUpdatedQueue[
				this._spotlightsUpdatedQueue.length - 1];

			this._spotlightsUpdatedQueue = [];

			if (lastElement)
			{
				logger.debug('_processSpotlightsUpdatedQueue() | emitting spotlights updated from queue');

				await this._roomClient.updateSpotlights(lastElement.currentSpotlights,
					lastElement.selectedSpotlights, lastElement.currentPage,
					lastElement.pagesCount);
			}
		}

		this._isProcessingSpotlightsUpdatedQueue = false;
	}

	_enqueueSpotlightsUpdated(params)
	{
		this._spotlightsUpdatedQueue.push(params);

		if (!this._isProcessingSpotlightsUpdatedQueue)
		{
			this._processSpotlightsUpdatedQueue();
		}
	}

	requestSpotlightUpdate()
	{
		this._spotlightsUpdated();
	}

	_spotlightsUpdated()
	{
		let spotlights;

		if (this._hideNoVideoParticipants)
		{
			spotlights = this._peerList.filter((peerId) =>
				this._hasActiveVideo(peerId));
		}
		else
		{
			spotlights = this._peerList;
		}

		while (this._selectedSpotlights.length > this._maxSpotlights)
		{
			this._selectedSpotlights.shift();
		}

		if (this._selectedSpotlights.length > 0)
		{
			spotlights = [ ...new Set([ ...this._selectedSpotlights, ...spotlights ]) ];
		}

		// jeśli się inne rzeczy zmieniły (hideNoVideo itp) to update current page przez setter
		this.currentPage = this._currentPage;

		const pagesCount = this.getPagesCount();
		const start = (this._currentPage - 1) * this._maxSpotlights;
		const end = this._currentPage * this._maxSpotlights;

		this._currentSpotlights = spotlights.slice(start, end);

		logger.debug('_spotlightsUpdated() | spotlights updated, putting into queue');

		this._enqueueSpotlightsUpdated({
			currentSpotlights  : this._currentSpotlights,
			selectedSpotlights : this._selectedSpotlights,
			currentPage        : this._currentPage,
			pagesCount         : pagesCount
		});
	}

	_arraysEqual(arr1, arr2)
	{
		if (arr1.length !== arr2.length)
			return false;

		for (let i = arr1.length; i--;)
		{
			if (arr1[i] !== arr2[i])
				return false;
		}

		return true;
	}

	get hideNoVideoParticipants()
	{
		return this._hideNoVideoParticipants;
	}

	set hideNoVideoParticipants(hideNoVideoParticipants)
	{
		const oldHideNoVideoParticipants = this._hideNoVideoParticipants;

		this._hideNoVideoParticipants = hideNoVideoParticipants;

		if (oldHideNoVideoParticipants !== this._hideNoVideoParticipants)
			this._spotlightsUpdated();
	}

	get maxSpotlights()
	{
		return this._maxSpotlights;
	}

	set maxSpotlights(maxSpotlights)
	{
		const oldMaxSpotlights = this._maxSpotlights;

		this._maxSpotlights = maxSpotlights;

		if (oldMaxSpotlights !== this._maxSpotlights)
			this._spotlightsUpdated();
	}

	getPagesCount()
	{
		let peerCount = this._peerList.length;

		if (this._hideNoVideoParticipants)
		{
			peerCount = this._peerList.filter((peerId) =>
				this._hasActiveVideo(peerId)).length;
		}

		const count = Math.ceil(peerCount / this._maxSpotlights);

		if (count < 1)
			return 1;
		else
			return count;
	}

	get currentPage()
	{
		return this._currentPage;
	}

	set currentPage(currentPage)
	{
		const oldCurrentPage = this._currentPage;
		const pagesCount = this.getPagesCount();

		if (currentPage < 1)
			this._currentPage = 1;
		else if (currentPage > pagesCount)
			this._currentPage = pagesCount;
		else
			this._currentPage = currentPage;

		if (oldCurrentPage !== this._currentPage)
			this._spotlightsUpdated();
	}
}
