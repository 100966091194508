const initialState = {
	transportsStats    : {},
	producersStats     : {},
	peerProducersStats : {}
};

const stats = (state = initialState, action) =>
{
	switch (action.type)
	{
		case 'SET_TRANSPORTS_STATS':
		{
			const { transportsStats } = action.payload;

			return { ...state, transportsStats };
		}

		case 'SET_PRODUCERS_STATS':
		{
			const { producersStats } = action.payload;

			return { ...state, producersStats };
		}

		case 'SET_PEER_PRODUCERS_STATS':
		{
			const { peerProducersStats } = action.payload;

			return { ...state, peerProducersStats };
		}

		default:
			return state;
	}
};

export default stats;
