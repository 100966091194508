const initialState = {};

const producers = (state = initialState, action) =>
{
	switch (action.type)
	{
		case 'SET_SNAPSHOT_PRODUCER':
		{
			const { producerId, snapshot } = action.payload;

			const newProducer = { ...state[producerId], snapshot };

			return { ...state, [producerId]: newProducer };
		}

		case 'REMOVE_SNAPSHOT_PRODUCER':
		{
			const { producerId } = action.payload;

			const newProducer = { ...state[producerId], snapshot: null };

			return { ...state, [producerId]: newProducer };
		}

		case 'ADD_PATH_TO_DRAW_PRODUCER':
		{
			const { producerId, path, srcWidth } = action.payload;

			const producer = state[producerId];

			if (!producer)
				return state;

			let newPathsToDraw = producer.pathsToDraw;

			if (!newPathsToDraw)
			{
				newPathsToDraw = [];
			}

			// hack to chnage the array object reference
			newPathsToDraw = [ ...newPathsToDraw ];

			newPathsToDraw.push({ path: path, srcWidth: srcWidth });

			const newProducer = { ...producer, pathsToDraw: newPathsToDraw };

			return { ...state, [producerId]: newProducer };
		}

		case 'REMOVE_DRAWINGS_PRODUCER':
		{
			const { producerId } = action.payload;

			if (!producerId)
			{
				const newState = { ...state };

				Object.values(state).forEach((producer) =>
				{
					if (producer.pathsToDraw)
					{
						const newProducer = { ...producer, pathsToDraw: [] };

						newState[newProducer.id] = newProducer;
					}
				});

				return newState;
			}
			else
			{
				const producer = state[producerId];

				if (producer)
				{
					const newProducer = { ...producer, pathsToDraw: [] };

					return { ...state, [producerId]: newProducer };
				}
			}

			return state;
		}

		case 'ADD_PRODUCER':
		{
			const { producer } = action.payload;

			return { ...state, [producer.id]: producer };
		}

		case 'REMOVE_PRODUCER':
		{
			const { producerId } = action.payload;
			const newState = { ...state };

			delete newState[producerId];

			return newState;
		}

		case 'SET_PRODUCER_PAUSED':
		{
			const { producerId, originator } = action.payload;
			const producer = state[producerId];

			let newProducer;

			if (originator === 'local')
				newProducer = { ...producer, locallyPaused: true };
			else
				newProducer = { ...producer, remotelyPaused: true };

			return { ...state, [producerId]: newProducer };
		}

		case 'SET_PRODUCER_RESUMED':
		{
			const { producerId, originator } = action.payload;
			const producer = state[producerId];

			let newProducer;

			if (originator === 'local')
				newProducer = { ...producer, locallyPaused: false };
			else
				newProducer = { ...producer, remotelyPaused: false };

			return { ...state, [producerId]: newProducer };
		}

		case 'SET_PRODUCER_TRACK':
		{
			const { producerId, track } = action.payload;
			const producer = state[producerId];
			const newProducer = { ...producer, track };

			return { ...state, [producerId]: newProducer };
		}

		case 'SET_PRODUCERS_SCORES':
		{
			const { producersScores } = action.payload;

			const changedProducers = {};

			for (const [ producerId, score ] of Object.entries(producersScores))
			{
				const producer = state[producerId];

				if (producer && producer.score !== score)
					changedProducers[producerId] = { ...producer, score };
			}

			if (Object.keys(changedProducers).length === 0)
				return state;

			const newState = { ...state };

			for (const [ producerId, producer ] of Object.entries(changedProducers))
				newState[producerId] = producer;

			return newState;
		}

		default:
			return state;
	}
};

export default producers;
