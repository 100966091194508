import { config } from '../../config';

const initialState =
{
	displayName                : `Guest ${Math.floor(Math.random() * (100000 - 10000)) + 10000}`,
	selectedWebcam             : null,
	selectedExtraVideos        : [],
	selectedAudioDevice        : null,
	advancedMode               : false,
	autoGainControl            : config.autoGainControl,
	echoCancellation           : config.echoCancellation,
	noiseSuppression           : config.noiseSuppression,
	voiceActivatedUnmute       : config.voiceActivatedUnmute,
	noiseThreshold             : config.noiseThreshold,
	audioMuted                 : false,
	videoMuted                 : false,
	resolution                 : null,
	frameRate                  : config.frameRate,
	videoCodec                 : config.videoCodec,
	screenSharingResolution    : config.screenResolution,
	screenSharingFrameRate     : config.screenSharingFrameRate,
	screenSharingCodec         : config.screenSharingCodec,
	recorderPreferredMimeType  : config.defaultRecorderMimeType,
	lastN                      : 4,
	permanentTopBar            : true,
	hiddenControls             : config.hiddenControls,
	showNotifications          : true,
	notificationSounds         : config.playNotificationSounds,
	mirrorOwnVideo             : true,
	hideNoVideoParticipants    : false,
	videoBackgroundEffect      : 'none',
	buttonControlBar           : config.buttonControlBar,
	drawerOverlayed            : config.drawerOverlayed,
	aspectRatio                : config.aspectRatio,
	mediaPerms                 : { audio: true, video: true },
	localPicture               : null,
	audioPreset                : config.audioPreset,
	audioPresets               : config.audioPresets,
	sampleRate                 : config.sampleRate,
	channelCount               : config.channelCount,
	sampleSize                 : config.sampleSize,
	opusStereo                 : config.opusStereo,
	opusDtx                    : config.opusDtx,
	opusFec                    : config.opusFec,
	opusPtime                  : config.opusPtime,
	opusMaxPlaybackRate        : config.opusMaxPlaybackRate,
	enableOpusDetails          : false,
	enableSnaphots             : false,
	showRemoveDrawingsPerVideo : false,
	drawingAlwaysOn            : true,
	in3dModeView               : false,
	in3dModeSend               : false,
	clientId                   : null,
	highPassFilter             : false
};

const settings = (state = initialState, action) =>
{
	switch (action.type)
	{
		case 'CHANGE_WEBCAM':
		{
			return { ...state, selectedWebcam: action.payload.deviceId };
		}

		case 'ADD_SELECTED_EXTRA_VIDEO':
		{
			const { deviceId } = action.payload;

			if (state.selectedExtraVideos.includes(deviceId))
				return state;

			const selectedExtraVideos = [ ...state.selectedExtraVideos, deviceId ];

			return { ...state, selectedExtraVideos };
		}

		case 'REMOVE_SELECTED_EXTRA_VIDEO':
		{
			const { deviceId } = action.payload;

			const selectedExtraVideos =
				state.selectedExtraVideos.filter((listDeviceId) =>
					listDeviceId !== deviceId);

			return { ...state, selectedExtraVideos };
		}

		case 'CHANGE_AUDIO_DEVICE':
		{
			return { ...state, selectedAudioDevice: action.payload.deviceId };
		}

		case 'CHANGE_AUDIO_OUTPUT_DEVICE':
		{
			return { ...state, selectedAudioOutputDevice: action.payload.deviceId };
		}

		case 'SET_DISPLAY_NAME':
		{
			const { displayName } = action.payload;

			return { ...state, displayName };
		}

		case 'SET_CLIENT_ID':
		{
			const { clientId } = action.payload;

			return { ...state, clientId };
		}

		case 'TOGGLE_ADVANCED_MODE':
		{
			const advancedMode = !state.advancedMode;

			return { ...state, advancedMode };
		}

		case 'SET_VIDEO_BACKGROUND_EFFECT':
		{
			const { videoBackgroundEffect } = action.payload;

			return { ...state, videoBackgroundEffect };
		}

		case 'TOGGLE_HIGH_PASS_FILTER':
		{
			const highPassFilter = !state.highPassFilter;

			return { ...state, highPassFilter };
		}

		case 'TOGGLE_ENABLE_SNAPSHOTS':
		{
			const enableSnaphots = !state.enableSnaphots;

			return { ...state, enableSnaphots };
		}

		case 'TOGGLE_DRAWING_ALWAYS_ON':
		{
			const drawingAlwaysOn = !state.drawingAlwaysOn;

			return { ...state, drawingAlwaysOn };
		}

		case 'TOGGLE_IN_3D_MODE_VIEW':
		{
			const in3dModeView = !state.in3dModeView;

			return { ...state, in3dModeView };
		}

		case 'TOGGLE_IN_3D_MODE_SEND':
		{
			const in3dModeSend = !state.in3dModeSend;

			return { ...state, in3dModeSend };
		}

		case 'TOGGLE_SHOW_REMOVE_DRAWINGS_PER_VIDEO':
		{
			const showRemoveDrawingsPerVideo = !state.showRemoveDrawingsPerVideo;

			return { ...state, showRemoveDrawingsPerVideo };
		}

		case 'SET_SAMPLE_RATE':
		{
			const { sampleRate } = action.payload;

			return { ...state, sampleRate, opusMaxPlaybackRate: sampleRate };
		}

		case 'SET_CHANNEL_COUNT':
		{
			const { channelCount } = action.payload;

			return { ...state, channelCount, opusStereo: channelCount > 1 };
		}

		case 'SET_VOLUME':
		{
			const { volume } = action.payload;

			return { ...state, volume };
		}

		case 'SET_AUTO_GAIN_CONTROL':
		{
			const { autoGainControl } = action.payload;

			return { ...state, autoGainControl };
		}

		case 'SET_AUDIO_PRESET':
		{
			const { audioPreset } = action.payload;

			return { ...state, audioPreset };
		}

		case 'SET_ECHO_CANCELLATION':
		{
			const { echoCancellation } = action.payload;

			return { ...state, echoCancellation };
		}

		case 'SET_NOISE_SUPPRESSION':
		{
			const { noiseSuppression } = action.payload;

			return { ...state, noiseSuppression };
		}

		case 'SET_VOICE_ACTIVATED_UNMUTE':
		{
			const { voiceActivatedUnmute } = action.payload;

			return { ...state, voiceActivatedUnmute };
		}

		case 'SET_NOISE_THRESHOLD':
		{
			const { noiseThreshold } = action.payload;

			return { ...state, noiseThreshold };
		}

		case 'SET_OPUS_STEREO':
		{
			const { opusStereo } = action.payload;

			return { ...state, opusStereo };
		}

		case 'SET_OPUS_DTX':
		{
			const { opusDtx } = action.payload;

			return { ...state, opusDtx };
		}

		case 'SET_OPUS_FEC':
		{
			const { opusFec } = action.payload;

			return { ...state, opusFec };
		}

		case 'SET_OPUS_PTIME':
		{
			const { opusPtime } = action.payload;

			return { ...state, opusPtime };
		}

		case 'SET_OPUS_MAX_PLAYBACK_RATE':
		{
			const { opusMaxPlaybackRate } = action.payload;

			return { ...state, opusMaxPlaybackRate };
		}

		case 'SET_ENABLE_OPUS_DETAILS':
		{
			const { enableOpusDetails } = action.payload;

			return { ...state, enableOpusDetails };
		}

		case 'SET_DEFAULT_AUDIO':
		{
			const { audio } = action.payload;

			return { ...state, audio };
		}

		case 'SET_SAMPLE_SIZE':
		{
			const { sampleSize } = action.payload;

			return { ...state, sampleSize };
		}

		case 'SET_ASPECT_RATIO':
		{
			const { aspectRatio } = action.payload;

			return { ...state, aspectRatio };
		}

		case 'SET_LAST_N':
		{
			const { lastN } = action.payload;

			return { ...state, lastN };
		}

		case 'TOGGLE_PERMANENT_TOPBAR':
		{
			const permanentTopBar = !state.permanentTopBar;

			return { ...state, permanentTopBar };
		}

		case 'TOGGLE_BUTTON_CONTROL_BAR':
		{
			const buttonControlBar = !state.buttonControlBar;

			return { ...state, buttonControlBar };
		}

		case 'TOGGLE_DRAWER_OVERLAYED':
		{
			const drawerOverlayed = !state.drawerOverlayed;

			return { ...state, drawerOverlayed };
		}

		case 'TOGGLE_HIDDEN_CONTROLS':
		{
			const hiddenControls = !state.hiddenControls;

			return { ...state, hiddenControls };
		}

		case 'TOGGLE_NOTIFICATION_SOUNDS':
		{
			const notificationSounds = !state.notificationSounds;

			return { ...state, notificationSounds };
		}

		case 'TOGGLE_SHOW_NOTIFICATIONS':
		{
			const showNotifications = !state.showNotifications;

			return { ...state, showNotifications };
		}

		case 'SET_VIDEO_RESOLUTION':
		{
			const { resolution } = action.payload;

			return { ...state, resolution };
		}

		case 'SET_VIDEO_FRAME_RATE':
		{
			const { frameRate } = action.payload;

			return { ...state, frameRate };
		}

		case 'SET_SCREEN_SHARING_RESOLUTION':
		{
			const { screenSharingResolution } = action.payload;

			return { ...state, screenSharingResolution };
		}

		case 'SET_SCREEN_SHARING_FRAME_RATE':
		{
			const { screenSharingFrameRate } = action.payload;

			return { ...state, screenSharingFrameRate };
		}

		case 'TOGGLE_MIRROR_OWN_VIDEO':
		{
			const mirrorOwnVideo = !state.mirrorOwnVideo;

			return { ...state, mirrorOwnVideo };
		}

		case 'TOGGLE_HIDE_NO_VIDEO_PARTICIPANTS':
		{
			const hideNoVideoParticipants = !state.hideNoVideoParticipants;

			return { ...state, hideNoVideoParticipants };
		}

		case 'SET_MEDIA_PERMS':
		{
			const { mediaPerms } = action.payload;

			return { ...state, mediaPerms };
		}

		case 'SET_AUDIO_MUTED':
		{
			const { audioMuted } = action.payload;

			return { ...state, audioMuted };
		}

		case 'SET_VIDEO_MUTED':
		{
			const { videoMuted } = action.payload;

			return { ...state, videoMuted };
		}

		case 'SET_LOCAL_PICTURE':
		{
			const { localPicture } = action.payload;

			return { ...state, localPicture };
		}

		case 'SET_RECORDER_SUPPORTED_MIME_TYPES':
		{
			const { recorderSupportedMimeTypes } = action.payload;

			return { ...state, recorderSupportedMimeTypes };
		}

		case 'SET_RECORDER_PREFERRED_MIME_TYPE':
		{
			const { recorderPreferredMimeType } = action.payload;

			return { ...state, recorderPreferredMimeType };
		}

		case 'SET_VIDEO_CODEC':
		{
			const { videoCodec } = action.payload;

			return { ...state, videoCodec };
		}

		case 'SET_SCREEN_SHARING_CODEC':
		{
			const { screenSharingCodec } = action.payload;

			return { ...state, screenSharingCodec };
		}

		default:
			return state;
	}
};

export default settings;
